import './App.css';
import Payment from './views/Payment';
import Header from './views/Header';

function App() {
  return (
    <div className="App">
      <Header />
      <Payment />
    </div>
  );
}

export default App;
