import React from "react";

function Modal() {
  return (
      <div className="modalContainer">
        <span>Processing: Please do not hit the back button.</span>
        <span>This window will close when finished.</span>
        <div className="loader"></div>
      </div>
  );
}

export default Modal;