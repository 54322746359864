import React from "react";


function Header () {
    return (
        <header className="header" >
        	<div className="contact" >
				<div className="contact-container">
					<a href="tel:8019032626">
		    			<span>Call +1.801.903.2626</span>
					</a>
				</div>
            </div>
        	<div className="topnav" id="myTopnav">
				<div className="logo">
					<img src="https://ampprobation.com/wp-content/uploads/2013/01/amp-logo.png" alt="Amp Probation"/>
				</div>
				<div className="links">
					<a href="https://ampprobation.com/"><span>Home</span></a>
					<div className="dropdown">
						<span>Monitoring Solutions</span>
						<i className="arrow down"></i>
						<div className="dropdown-content">
							<a href="https://ampprobation.com/scram-cam-provides-accountability-and-encourages-compliance/">SCRAM Continuous Alcohol Monitoring</a>
							<a href="https://ampprobation.com/gps-monitoring/">GPS Monitoring</a>
							<a href="https://ampprobation.com/scram-remote-breath-alcohol-tester-with-gps-location-monitoring/">Remote Breath Alcohol Monitoring</a>
							<a href="https://ampprobation.com/house-arrest-curfew/">House Arrest / Curfew</a>
							<a href="https://ampprobation.com/services/probation/">Supervised Private Probation</a>
							<a href="https://ampprobation.com/24-7-drug-testing-patch/">24/7 Drug Testing Patch</a>
							<a href="https://ampprobation.com/services/assessments/">Assessments</a>
						</div>
					</div>
					<div className="dropdown">
						<span>Programs</span>
						<i className="arrow down"></i>
						<div className="dropdown-content">
							<a href="https://ampprobation.com/annies-bill-coming-soon/">Annies Bill (Coming Soon)</a>
							<a href="https://ampprobation.com/domestic-violence/">Domestic Violence</a>
							<a href="https://ampprobation.com/dui-dwi-courts/">DUI/DWI Courts</a>
							<a href="https://ampprobation.com/interlock-affidavit/">Interlock Affidavit</a>
							<a href="https://ampprobation.com/pre-trial-release/">Pre-trial Release</a>
							<a href="https://ampprobation.com/sheriffs/">Sheriffs</a>
							<a href="https://ampprobation.com/scram-programs-in-specialty-courts/">Speciality Courts</a>
							<a href="https://ampprobation.com/target-25-programs/">Target 25 Programs</a>
						</div>
					</div>
					<div className="dropdown">
						<span>Clients</span>
						<i className="arrow down"></i>
						<div className="dropdown-content">
							<a href="https://ampprobation.com/community-resources/">Community Resources</a>
							<a href="https://www.utcourts.gov/">Court Hearings</a>
							<a href="https://www.webex.com/downloads.html">Download Webex</a>
							<a href="https://ampprobation.com/frequently-asked-questions/">Frequently Asked Questions</a>
							<a href="https://payment.ampprobation.com/">Payment</a>
							<a href="https://secure.utah.gov/warrants/index.html">See if I Have a Warrant</a>
							<a href="https://www.utcourts.gov/selfhelp/contact/">Utah Courts Self Help</a>
						</div>
					</div>
					<a href="https://payment.ampprobation.com/"><span>Payment</span></a>
					<div className="dropdown">
						<span>About Us</span>
						<i className="arrow down"></i>
						<div className="dropdown-content">
							<a href="https://ampprobation.com/contact-us/">Contact Us</a>
							<a href="https://ampprobation.com/contact-us/">Locations</a>
						</div>
					</div>
					<a href="https://ampprobation.com/blog/"><span>Blog</span></a>
					<a href="https://ampprobation.com/home/" className="icon" >&#9776;</a>
				</div>
			</div>
	</header>
    )
}

export default Header;