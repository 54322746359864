import { initializeApp } from 'firebase/app';
import { getFunctions } from 'firebase/functions';
import { initializeAppCheck, ReCaptchaV3Provider } from "firebase/app-check";

const app = initializeApp({
  apiKey: "AIzaSyD5uLdpWyLbnYudrXX63bHV9wRWvEEnf-g",
  authDomain: "clover-amp-payment.firebaseapp.com",
  projectId: "clover-amp-payment",
  storageBucket: "clover-amp-payment.appspot.com",
  messagingSenderId: "153967710140",
  appId: "1:153967710140:web:5b41fcfcc3dbe4f8dce5b9",
  databaseURL: "https://clover-amp-payment-default-rtdb.firebaseio.com"  
});

export const functions = getFunctions(app);
export const appCheck = initializeAppCheck(app, {
provider: new ReCaptchaV3Provider('6Lf6f48pAAAAAEVHP1f6e9kV7DXUy_ujx2Sw7VU8'),
  // Optional argument. If true, the SDK automatically refreshes App Check
  // tokens as needed.
  isTokenAutoRefreshEnabled: true
});