import React, { useState, useEffect } from "react";
import { functions } from "../../utils/firebase";
import useRecaptchaV3 from "./hooks/reCaptcha";
import { httpsCallable } from "firebase/functions"
import Modal from "../Payment/components/modal";
import axios from "axios";

function Payment () {

  const [inputs, setInputs] = useState({
    'clName': '',
    'cName': '',
    'email': '',
    'caseManager': '',
    'bZip': '',
    'cNum': '',
    'cExpiryMonth': '',
    'cType': '',
    'cExpiryYear': '',
    'cvv': '',
    'amount': '',
    'fee': '0',
    'total': '0',
    'ip': '',
    'rToken': ''
});
  const [managers, setCaseManagers] = useState({});
  const [managerNames, setCaseManagerNames] = useState([]);
  const [chargeResult, setChargeResult] = useState();
  const [isChargeComplete, setIsChargeComplete] = useState(false);
  const [isCharging, setIsCharing] = useState(false);
  const ccYears = ['2024','2025','2026','2027','2028','2029','2030','2031','2032','2033'];
  const ccMonths = ['01','02','03','04','05','06','07','08','09','10','11','12'];
  const [inputErrors, setInputErrors] = useState({});

  const charge = httpsCallable(functions, 'chargePayment');
  const sendEmail = httpsCallable(functions, 'sendPaymentNotification');
  const executeRecaptcha = useRecaptchaV3('6Lf6f48pAAAAAEVHP1f6e9kV7DXUy_ujx2Sw7VU8');
  
  useEffect(() => {
    const caseManagers = httpsCallable(functions, 'retrieveManagers');
    caseManagers().then(result => {
      let mNames = Object.keys(result.data);
      mNames.push("I don't know");
      mNames = mNames.reverse();
      setCaseManagers(result);
      setCaseManagerNames(mNames);
    })
  }, []);

  useEffect(() => {
    if (chargeResult && chargeResult.status === "succeeded") {
      setIsCharing(false);
      setIsChargeComplete(true);
      prepAndSendEmail();
    } else if (chargeResult) {
      if (chargeResult && chargeResult.status && chargeResult.status !== "succeeded") {
        setChargeResult(JSON.stringify(chargeResult));
      } else {
        setChargeResult(chargeResult);
      }
      setIsCharing(false);
      setIsChargeComplete(true);
      clearInputs();
    } else {
      setIsCharing(false);
      setIsChargeComplete(false);
      clearInputs();
    }
}, [chargeResult])

  useEffect(() => {
    //passing getData method to the lifecycle method
    getIpData();
  }, []);

  useEffect(() => {
    if (Object.keys(inputErrors).length === 0 && isCharging) {
      chargePayment();
    } else {
      setIsCharing(false);
    }
  }, [inputs.rToken])


  const getIpData = async () => {
    const res = await axios.get("https://api.ipify.org/?format=json");
    setInputs({ ...inputs, ip: res.data.ip});
  };

  const setCardType = (e) => {
    let cardNum = (e.target.value).replace(/ /g, '');
    let firstNum = cardNum.charAt(0);

    switch(firstNum) {
      case '2': 
        setInputs({ ...inputs,
          cNum: (e.target.value).replace(/ /g, ''),
          cType: 'MC'
        });
        break;
      case '3':
        setInputs({ ...inputs,
          cNum: (e.target.value).replace(/ /g, ''),
          cType: 'AMEX'
        });
        break;
      case '4':
        setInputs({ ...inputs,
          cNum: (e.target.value).replace(/ /g, ''),
          cType: 'VISA'
        });
        break;
      case '5':
        setInputs({ ...inputs,
          cNum: (e.target.value).replace(/ /g, ''),
          cType: 'MC'
        });
        break;
      case '6':
        setInputs({ ...inputs,
          cNum: (e.target.value).replace(/ /g, ''),
          cType: 'DISCOVER'
        });
        break;
      default:
        setInputs({ ...inputs,
          cNum: (e.target.value).replace(/ /g, ''),
          cType: ''
        });
    }
  }

  const setAmount = (e) => {
    var amt = e.target.value;

    amt = amt.replace("$", "");
    amt = amt.replace(" ", "");

    var processingFee = roundToTwo((amt * .0375)).toFixed(2);
    var  totalAmount = roundToTwo((amt * 1.0375)).toFixed(2);
    setInputs({ ...inputs, amount: e.target.value, fee: processingFee, total: totalAmount});

  }  

  function roundToTwo(num) {
    return +(Math.round(num + "e+2")  + "e-2");
  }

  const clearInputs = () => {
    setInputs(
      {
        'clName': '',
        'cName': '',
        'email': '',
        'caseManager': 'Please Select',
        'bZip': '',
        'cNum': '',
        'cExpiryMonth': '',
        'cType': '',
        'cExpiryYear': '',
        'cvv': '',
        'amount': '',
        'fee': '0',
        'total': '0',
        'ip': ''
      }
    )
  }

  const onSubmit = async () => {
    setIsCharing(true);
    await validateInputs(inputs); 
    const recaptchaToken = await executeRecaptcha();
    setInputs({...inputs, rToken: recaptchaToken});
  }
  
  const validateInputs = (ins) => {
    return new Promise((resolve) => {
      setTimeout(() => {
        const errors = {};
        const emailRegEx = /^[^\s@]+@[^\s@]+\.[^\s@]{2,}$/i;

        if (ins.caseManager === "") {
          ins.caseManager = "I Don't Know";
        }

        if (ins.cExpiryMonth === "") {
          ins.cExpiryMonth = "01";
        }

        if (ins.cExpiryYear === "") {
          ins.cExpiryYear = "2024";
        }

        if (ins.cType === "") {
          ins.cType = "AMEX";
        }

        if (ins.clName === "") {
          errors.clName = "Client Name is required!";
        }

        // if (ins.email !== "" && !emailRegEx.test(ins.email)) {
        //   errors.email = "Please provide a valid email format";
        // }

        if (ins.cName === "") {
          errors.cName = "Card Holder name is required!"
        }

        if (ins.bZip === "") {
          errors.bZip = "Billing Zip Code name is required!"
        }

        if (ins.cNum === "") {
          errors.cNum = "Card Number is required!"
        }

        if (ins.cvv === "") {
          errors.cvv = "CVV Number is required!"
        }

        if (ins.amount === "") {
          errors.amount = "Amount is Required"
        }
          setInputErrors(errors);
          resolve(errors)
      }, 2000);
    }
  )}

  const chargePayment = async () => {
    return new Promise((resolve, reject) => {
      setTimeout(() => {
          resolve(charge(inputs)
            .then((result) => {
              setChargeResult(result.data);
          }).catch((error) => {
              setChargeResult(error);
              reject(error);
          }));
      }, 30000);
    })
  };

  const prepAndSendEmail = () => {
    const emailData = {};
    if (inputs.caseManager === "I Don't Know") {
      emailData.recipient = "";
    } else {
      emailData.recipient = managers.data[inputs.caseManager];
    }
    emailData.cName = inputs.clName;
    emailData.cdName = inputs.cName;
    emailData.amount = inputs.amount;
    emailData.total = inputs.total;
    emailData.status = chargeResult.status;
    emailData.description = chargeResult.description
    emailData.id=chargeResult.id;
    emailData.payment_method_details = chargeResult.payment_method_details;
    emailData.ref_num = chargeResult.ref_num;
    emailData.last4 = chargeResult.source.last4;
    emailData.caseManager = inputs.caseManager;
    sendEmail(emailData);
    clearInputs();
  }

  return (
    <div className="wrapper" >
      { isCharging
        ? 
          <Modal setOpenModal={isCharging}/> 
        : 
          isChargeComplete 
          ? 
          chargeResult.status === "succeeded" ?
          <div>
            <h3>Transaction Complete</h3>
            <div>Status: {chargeResult.status}</div>
            <div>Description: {chargeResult.description}</div>
            <div>Payment Method: {chargeResult.payment_method_details}</div>
            <div>ID: {chargeResult.id}</div>
            <div>Reference Number: {chargeResult.ref_num}</div>
            <div>Last 4: {chargeResult.source.last4}</div> 
            <div>Amount: {(chargeResult.amount / 100).toLocaleString("en-US", {style:"currency", currency:"USD"})}</div>
          </div>
          :
            <div>
              <h3>Transaction Failed</h3>
              {/* <div>Error Message: {chargeResult.error.message}</div>
              <div>Decline Code: {chargeResult.error.code}</div>
              <div>Charge Num: {chargeResult.error.charge}</div>
              <div>Message: {chargeResult.message}</div> */}
              <p></p>
              <div>Full Error String: {JSON.stringify(chargeResult)}</div>
              {/* <pre>{JSON.stringify(chargeResult, null, 2)}</pre> */}
            </div>
          :
          <form>
            <h1>Please Complete the Payment Form: </h1>
            <div className="form-container">
              <div className="leftcol">
                <h3>Information:</h3>
                <label>Name</label>
                  { inputErrors.clName ? <p className="error">{inputErrors.clName}</p> : <></>}
                  <input type="text" placeholder="Name" onChange={(e) => setInputs({ ...inputs, clName: String(e.target.value)})} value={inputs.clName} />
                <label>Email Address</label>
                  { inputErrors.email ? <p className="error">{inputErrors.email}</p> : <></>} 
                  <input type="email" placeholder="Email Address" onChange={(e) => setInputs({ ...inputs, email: String(e.target.value)})} value={inputs.email} />            
                <label>Case Manager</label>
                  <select onChange={(e) => setInputs({ ...inputs, caseManager: e.target.value})} value={String(inputs.caseManager)}>
                    {(managerNames).map((managerName) => (
                      <option key={managerName}>{managerName}</option>
                    ))}
                  </select>
                <label>Card Holder Name</label>
                  { inputErrors.cName ? <p className="error">{inputErrors.cName}</p> : <></>}
                  <input placeholder="Card Holder Name" type="text" id="cName" onChange={(e) => setInputs({ ...inputs, cName: String(e.target.value)})} value={inputs.cName}/>
                <label>Card Number</label>
                  { inputErrors.cNum ? <p className="error">{inputErrors.cNum}</p> : <></>}
                  <input placeholder="Card Number" type="text" onChange={(e) => setCardType(e)} value={inputs.cNum} />
                <div className="ccexp">
                  <div><label>Card Expiration Month</label>
                    <select type="text" onChange={(e) => setInputs({ ...inputs, cExpiryMonth: e.target.value})} value={inputs.cExpiryMonth}>
                        {ccMonths.map((month) => (
                          <option key={month}> {month} </option>
                        ))}
                    </select></div>
                  <div><label>Card Expiration Year</label>
                  <select type="text" onChange={(e) => setInputs({ ...inputs, cExpiryYear: e.target.value})} value={inputs.cExpiryYear}>
                    {ccYears.map((year) => (
                      <option key={year}> {year} </option>
                    ))}
                  </select>
                  </div>
                  <div><label>CVV</label>
                    { inputErrors.cvv ?<p className="error">{inputErrors.cvv}</p> : <></>}
                    <input placeholder="CVV Number" type="text" onChange={(e) => setInputs({ ...inputs, cvv: e.target.value})} value={inputs.cvv} />
                </div>
                  { inputErrors.cType ? <p className="error">{inputErrors.cType}</p> : <></>}</div>
                  <label>Card Type </label>
                  <select type="text" placeholder="--" onChange={(e) => setInputs({ ...inputs, cType: e.target.value})} value={inputs.cType}>
                    <option value='AMEX'>American Express</option>
                    <option value='DISCOVER'>Discover</option>
                    <option value='MC'>Mastercard</option>
                    <option value='VISA'>Visa</option>
                  </select>
                  <label>Billing Zip Code</label> 
                  { inputErrors.bZip ? <p className="error">{inputErrors.bZip}</p> : <></>}
                  <input placeholder="Billing Zip Code" type="text" onChange={(e) => setInputs({ ...inputs, bZip: e.target.value})} value={String(inputs.bZip)} />
              </div>
              <div className="rightcol">
                <h3>Totals:</h3>
                { inputErrors.amount ? <p className="error">{inputErrors.amount}</p> : <></>}
                <label>Payment Amount</label>
                  <input placeholder="$0.00" type="text" onChange={(e) => setAmount(e)} value={inputs.amount} />
                <label>Service Fee </label>
                  <input type="text" readOnly={true} value={"$" + inputs.fee} />
                <label>Total  Amount</label>
                  <input id="total" type="text" readOnly={true} value={"$" + inputs.total} />
                  <button type="button" onClick={onSubmit}>Submit</button>
              </div>
            </div>
          </form>
      }
    </div>
  )
}

export default Payment;